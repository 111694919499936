
  import { ref, defineComponent,onMounted } from 'vue'
  import pageTitle from '@/components/pageTitle.vue';
  import { ElMessage,ElMessageBox } from 'element-plus';
  import { getStoreTaskList,getStationList,getPersonList,saveStoreTask } from '@/axios/moveTV';
  type tab = {
    id: number,
    name: string,
    store_task_pasv: {
      id: number,
      station_id: string,
      allot_mode: number,
      last_user_id: number,
      task_duration: number,
      grab_number: number,
      trigger_duration: number,
      accept_duration: number,
    }
  }
  type forms = {
    name: string,
    zw?: number[],
    allot_mode: number,
    task_duration: number,
    grab_number: number,
    trigger_duration: number,
    accept_duration: number,
    jd?: number,
    sys_task_id: number,
    station_id?: string,
    user_id?: number,
    company_id?: number
  }
  export default{
    setup() {
      let tableData = ref([])
      let dialogs = ref(false)
      let gwArr = ref([{id:'',name:''}]);
      let peoArr = ref([{name:'',user_id:''}])
      let form = ref<forms>({
        name: '',
        allot_mode: 0,
        task_duration: 0,
        grab_number: 0,
        trigger_duration: 0,
        accept_duration: 0,
        sys_task_id: 0,
        station_id: ''
      })
      onMounted(() => {
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')

        getPersonList({
          company_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id,
          area_id: '',
          station_id: '',
          name: ''
        }).then((data:any) => {
          peoArr.value = data;
        }).catch((err:any)=>{
        
        })

        //职务
        getStationList({
          company_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id
        }).then((data:any) => {
          gwArr.value = data;
          getStoreTaskLists();
        }).catch((err:any) => {
          console.log(err)
        })
      })

      //任务
      function getStoreTaskLists(){
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
        getStoreTaskList({
          company_id: aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id
        }).then((data:any) => {
          data.map((item:any) => {
            if(!item.store_task_pasv){
              item.store_task_pasv = {
                id: 0,
                station_id: '',
                allot_mode: 0,
                last_user_id: 0,
                task_duration: 1,
                grab_number: 1,
                trigger_duration: 0,
                accept_duration: 1
              }
              return item;
            }
          })
          tableData.value = data;
        })
      }

      //职务
      function showZW(val:string){
        if(!val) return
        let arr = val.split(','), info='';
        gwArr.value.map(item => {
          if(arr.indexOf(item.id.toString()) > -1){
            info = info + item.name + ','
          }
        })
        info = info.substring(0,info.length-1);
        return info;
      }
      //接单兜底
      function showPEO(val:string){
        if(!val) return
        let arr = val.split(','), info='';
        peoArr.value.map(item => {
          if(arr.indexOf(item.user_id.toString()) > -1){
            info = item.name + ','
          }
        })
        info = info.substring(0,info.length-1);
        return info;
      }
      //编辑
      function showEdit(val:tab){
        if(!val.store_task_pasv.id){
          val.store_task_pasv = {
            id: 0,
            station_id: '',
            allot_mode: 0,
            last_user_id: 0,
            task_duration: 1,
            grab_number: 1,
            trigger_duration: 0,
            accept_duration: 1
          }
        }
        let zwa = val.store_task_pasv.station_id ? val.store_task_pasv.station_id.split(',').map(Number) : [];
        // let jda = val.store_task_pasv.last_user_id
        form.value = {
          sys_task_id: val.id,
          name: val.name,
          allot_mode: val.store_task_pasv.allot_mode,
          zw: zwa,
          jd: val.store_task_pasv.last_user_id,
          task_duration: val.store_task_pasv.task_duration,
          grab_number: val.store_task_pasv.grab_number,
          accept_duration: val.store_task_pasv.accept_duration,
          trigger_duration: val.store_task_pasv.trigger_duration,
        }
        
        dialogs.value = true;
      }
      //确认
      function queryClick(){
        //自动接单
        // if(form.value.allot_mode == 3){
        //   form.value.trigger_duration = 0;
        // }
        let aci = JSON.parse(sessionStorage.getItem('accountInfo') || '')
        form.value['station_id'] = (form.value.zw || []).join(',');
        form.value['user_id'] = form.value.jd;
        form.value['company_id'] = aci.company_list[sessionStorage.getItem('checkCompany') || 0].company_id;

        delete form.value.jd;
        delete form.value.zw;
        console.log(form.value)
        saveStoreTask(form.value).then((data:any) => {
          ElMessage('编辑成功');
          getStoreTaskLists();
          dialogs.value = false;
        }).catch((err:any)=>{})
      }

      return {
        tableData,
        dialogs,
        form,
        gwArr,
        peoArr,
        showZW,
        showPEO,
        showEdit,
        queryClick
      }
    },
    components: {
      pageTitle
    }
  }
